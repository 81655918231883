<template>
  <div id="select-page" :class="{'dark-mode-content-deep': $store.state.isDarkMode}">
    <h1 :class="{'font-color': $store.state.isDarkMode}">{{ title }}</h1>
    <div class="options-container">
      <div
        v-for="item in options"
        :key="item"
        :class="getUserSelectDomClassName(item)"
        @click="doSelect(item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentOption: "",
    };
  },
  props: {
    title: String,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    propName: String,
    step: Number,
    percentage: Number,
  },
  methods: {
    // 获取用户当前点击项的类名
    getUserSelectDomClassName(value) {
      if (this.currentOption === value) {
        if(this.$store.state.isDarkMode) {
          return "flex-y-center option-dark option-is-selected";
        }
        return "flex-y-center option option-is-selected";
      } else {
        if(this.$store.state.isDarkMode) {
          return "flex-y-center option-dark";
        }
        return "flex-y-center option";
      }
    },
    doSelect(item) {
      if (
        process.env.VUE_APP_LOAN_PATH === "_views/LoanInfo/no-edu-credit.vue"
      ) {
        if (item === "20万以上" || item === "10-20万") {
          localStorage.setItem("addMoney", 10000);
          if ((this.step = 1)) {
            this.$setHeaderMoney("90000");
          }
        } else if (item === "5-10万") {
          localStorage.setItem("addMoney", 5000);
          if ((this.step = 1)) {
            this.$setHeaderMoney("45000");
          }
        } else if (item === "1-5万") {
          localStorage.setItem("addMoney", 4000);
          if ((this.step = 1)) {
            this.$setHeaderMoney("6000");
          }
        } else if (item === "2000元-1万") {
          localStorage.setItem("addMoney", 500);
          if ((this.step = 1)) {
            this.$setHeaderMoney("4500");
          }
        }
        this.$setHeaderMoney(
          this.$getHeaderMoney() + ~~localStorage.getItem("addMoney")
        );
      } else {
        if (item.indexOf("无") === -1 && !this.currentOption) {
          const moneyArr = [3000, 5000, 7000];
          const number = Math.floor(Math.random() * 3);
          this.$setHeaderMoney(this.$getHeaderMoney() + moneyArr[number]);
        }
      }
      this.$setHeaderPercentage(this.percentage);
      this.currentOption = item;
      const userInfo = this.$getUserInfo();
      userInfo[this.propName] = this.currentOption;
      this.$setUserInfo(userInfo);
      this.$emit("changeActive", this.step);
    },
  },
  created() {
    this.currentOption = this.$getUserInfo()[this.propName] || "";
  },
};
</script>
<style lang="less" scoped>
#select-page {
  width: 100vw;
  background: #ffffff;

  h1 {
    margin: 1.4054rem 0 0.9459rem 0;
    font-size: 16px;
    font-weight: 600;
    color: #2e2f31;
    text-align: center;
  }

  .options-container {
    width: 100%;
    padding: 0 1.1351rem 0 1.1622rem;

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.733rem;
      height: 1.333rem;
      margin-bottom: 0.5405rem;
      background: #f6f6f6;
      border-radius: 0.667rem;
      font-size: 14px;
      font-weight: 400;
      color: #2e2f31;
    }

    .option-dark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.733rem;
      height: 1.333rem;
      margin-bottom: 0.5405rem;
      background: #f6f6f6;
      font-size: 14px;
      font-weight: 400;
      color: #2e2f31;
    }

    .option-is-selected {
      background: #325ef3 !important;
      color: #ffffff !important;
    }
  }
}
</style>
